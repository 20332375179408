<template>
	<div
		v-if="hasArticles"
		class="flex flex-col justify-center w-full mx-auto bg-[#FAFAFA] min-h-[316px] lg:min-h-[348px] border-y-2 border-gray-100"
	>
		<CarouselDefault
			id="blog-articles"
			class="max-w-5xl mx-auto"
			slider-classes="space-x-8 md:space-x-4 md:px-8"
			arrow-visibility="hidden"
			:header-text="$t('maverix-blog-articles')"
		>
			<template #slides>
				<BlogArticleCard
					v-for="article in articles"
					:key="article.uuid"
					:article="article"
					card-classes="w-64 md:w-80"
					class="pb-8 first:pl-8 last:pr-8 first:md:pl-0 last:md:pr-0"
					lazy-load
				/>
			</template>
			<template #footer>
				<div class="flex justify-center">
					<UILinkButton
						:to="localePath('/blog')"
						:text="$t('read-more-articles')"
					/>
				</div>
			</template>
		</CarouselDefault>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { BlogArticleProps } from '@/components/blog/types'
import { mapBlogArticles } from '@/utils/blogArticlesMap'
import { STORYBLOK_VERSION } from '@/constants/index.js'
import { useErrorReporter } from '@/composables/useErrorReporter'
const { reportError } = useErrorReporter(useBugsnag().notify)

const { locale } = useI18n({
	useScope: 'global'
})
const localePath = useLocalePath()

const storyblokApi = useStoryblokApi()
const { data, error } = await useAsyncData(
	`blog-carousel-${locale.value}`,
	async () => await storyblokApi.get('cdn/stories', {
		version: STORYBLOK_VERSION,
		starts_with: 'blog',
		is_startpage: false,
		language: locale.value,
		per_page: 3
	})
)
const noData = !data.value?.data?.stories?.length
if (noData) {
	reportError('204 - No blog content')
}
if (error.value) {
	reportError('API Error - No blog articles found')
}

const articles = computed<BlogArticleProps[]>(() => {
	return mapBlogArticles(data?.value?.data?.stories) || []
})
const hasArticles = computed(() => {
	return !!articles.value?.length
})
</script>
